import { toast } from 'react-toastify';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  call,
  put,
  SagaReturnType,
  select,
  takeLatest,
} from 'redux-saga/effects';

import * as api from 'src/api/api';
import { StoreState } from 'src/redux/store';
import {
  EntriesEntities,
  PagingParameters,
  VisibilityCategory,
  VisibilityMap,
  VisibilitySetting,
} from 'src/types/insights';
import { loadEntriesSuccess } from '../catalog/catalog-slice';

interface CatalogFiltersState {
  catalog: any;
  conversations: VisibilityMap;
  demographics: VisibilityMap;
  error: Error | undefined;
  filtersAreLoading: boolean;
  highlighters: VisibilityMap;
  internalCodes: VisibilityMap;
  participants: VisibilityMap;
  structuralCodes: VisibilityMap;
  thematicCodes: VisibilityMap;
  transcriptSearch: string;
}

const initialState: CatalogFiltersState = {
  catalog: {},
  conversations: {},
  demographics: {},
  error: undefined,
  filtersAreLoading: false,
  highlighters: {},
  internalCodes: {},
  participants: {},
  structuralCodes: {},
  thematicCodes: {},
  transcriptSearch: '',
};

const onRequestInitiated = (state: CatalogFiltersState, loading = false) => {
  state.error = undefined;
  state.filtersAreLoading = loading;
};

const onRequestSuccess = (state: CatalogFiltersState) => {
  state.error = undefined;
  state.filtersAreLoading = false;
};

const onRequestFailure = (
  state: CatalogFiltersState,
  action: PayloadAction<Error>,
  message: string
) => {
  state.error = action.payload;
  state.filtersAreLoading = false;
  toast.error(message, { position: toast.POSITION.BOTTOM_RIGHT });
};

const applyVisibility = (
  visibility: VisibilityMap,
  setting: VisibilitySetting
) => {
  const { id, visible } = setting;
  if (visible !== undefined) {
    return { ...visibility, [id]: visible };
  }
  delete visibility[id];
  return { ...visibility };
};

const slice = createSlice({
  name: 'catalog-filters',
  initialState,
  reducers: {
    resetVisibility(
      state,
      action: PayloadAction<VisibilityCategory | undefined>
    ) {
      if (action.payload === undefined) {
        state.conversations = {};
        state.demographics = {};
        state.highlighters = {};
        state.internalCodes = {};
        state.participants = {};
        state.structuralCodes = {};
        state.thematicCodes = {};
      } else {
        state[action.payload] = {};
      }
    },
    resetFiltersVisibility(
      state,
      action: PayloadAction<VisibilityCategory | undefined>
    ) {
      onRequestInitiated(state, true);
      if (action.payload === undefined) {
        state.conversations = {};
        state.demographics = {};
        state.highlighters = {};
        state.internalCodes = {};
        state.participants = {};
        state.structuralCodes = {};
        state.thematicCodes = {};
      } else {
        state[action.payload] = {};
      }
    },
    setTranscriptSearch(state, action: PayloadAction<string>) {
      state.transcriptSearch = action.payload;
    },
    setVisibility(state, action: PayloadAction<VisibilitySetting>) {
      const { category, id, visible } = action.payload;
      if (visible !== undefined) {
        state[category] = { ...state[category], [id]: visible };
      } else {
        delete state[category][id];
        state[category] = { ...state[category] };
      }
    },
    setFiltersVisibility(state, action: PayloadAction<VisibilitySetting>) {
      onRequestInitiated(state, true);
      const { category, id, visible } = action.payload;
      if (visible !== undefined) {
        state[category] = { ...state[category], [id]: visible };
      } else {
        delete state[category][id];
        state[category] = { ...state[category] };
      }
    },
    loadFilteredEntries(
      state: CatalogFiltersState,
      action: PayloadAction<PagingParameters>
    ) {
      onRequestInitiated(state, true);
    },
    loadFilteredEntriesSuccess(
      state: CatalogFiltersState,
      action: PayloadAction<EntriesEntities>
    ) {
      onRequestSuccess(state);
    },
    loadFilteredEntriesFailure(
      state: CatalogFiltersState,
      action: PayloadAction<Error>
    ) {
      onRequestFailure(state, action, 'Failed to load filtered entries');
    },
  },
});

export const {
  resetVisibility,
  resetFiltersVisibility,
  setTranscriptSearch,
  setVisibility,
  setFiltersVisibility,
  loadFilteredEntries,
  loadFilteredEntriesSuccess,
  loadFilteredEntriesFailure,
} = slice.actions;

export const actions = slice.actions;

export default slice.reducer;

export function* sagaLoadFilteredEntries(
  action: ReturnType<typeof loadFilteredEntries>
) {
  const appState: StoreState = yield select();
  try {
    let { catalogId } = action.payload;
    if (!catalogId) {
      catalogId = appState.catalog.catalog.id;
    }
    const limit = 50;
    const page = 1;
    const {
      conversations,
      highlighters,
      participants,
      thematicCodes,
      structuralCodes,
      internalCodes,
      demographics,
    } = appState.catalog_filters;

    const codes = Object.keys(thematicCodes).concat(
      Object.keys(structuralCodes),
      Object.keys(internalCodes),
      Object.keys(demographics)
    );
    const filteredEntries: SagaReturnType<typeof api.getEntries> = yield call(
      api.getEntries,
      {
        catalogId,
        limit,
        page,
        conversations: Object.keys(conversations) as any,
        highlighters: Object.keys(highlighters) as any,
        participants: Object.keys(participants) as unknown as any,
        codes: codes as any,
      }
    );
    yield put(loadEntriesSuccess(filteredEntries));
    yield put(loadFilteredEntriesSuccess(filteredEntries));
  } catch (err) {
    yield put(loadFilteredEntriesFailure(err as Error));
  }
}

export const sagas = [
  takeLatest(loadFilteredEntries.type, sagaLoadFilteredEntries),
  takeLatest(setFiltersVisibility.type, sagaLoadFilteredEntries),
  takeLatest(resetFiltersVisibility.type, sagaLoadFilteredEntries),
];
