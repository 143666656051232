import { useCallback, useEffect, useState } from 'react';
import { ExpandMoreRounded } from '@mui/icons-material';
import {
  Checkbox,
  Container,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';

import color from 'src/assets/_util.scss';
import Button from 'src/components/core/Button/Button';
import { VSpacer } from 'src/components/core/Spacer';
import { HStack } from 'src/components/core/Stack';
import {
  selectEntry,
  updateFilterJoin,
  updateFilters,
} from 'src/redux/catalog/catalog-slice';
import {
  Entry,
  Filter,
  FilterJoin,
  FilterName,
  FilterOperation,
} from 'src/types/insights';
import HighlightCard from '../Catalog/HighlightCard';
import { useHighlightsPageContext } from '../Catalog/HighlightsPageProvider';
import {
  checkFiltersUpdated,
  useFilterLabels,
  useFilterOperations,
  useFilterOptions,
  valuelessOperations,
} from '../utils/filters';

const FilterableHighlightGrid = () => {
  const {
    visibleHighlights,
    selectedHighlightIds,
    setSelectedHighlightIds,
    openFilters,
    filterJoin,
    selectedFilters,
    filters,
    initialFilter,
    setFilters,
    dispatch,
    t,
  } = useHighlightsPageContext();

  const onClick = useCallback(
    (entry: Entry) => dispatch(selectEntry(entry.id)),
    [dispatch]
  );

  const onChangeCheck = useCallback(
    (checked: boolean, highlight: Entry) => {
      const nowSelectedHighlights = new Set(selectedHighlightIds);
      nowSelectedHighlights[checked ? 'add' : 'delete'](highlight.id);
      setSelectedHighlightIds(nowSelectedHighlights);
    },
    [selectedHighlightIds, setSelectedHighlightIds]
  );

  const addFilter = () => {
    const newFilters = [...filters];
    newFilters.push(initialFilter);
    setFilters(newFilters);
  };

  const updateFilter = (ind: number, filter: Filter) => {
    const newFilters = [...filters];
    newFilters.splice(ind, 1, filter);
    setFilters(newFilters);
  };

  const deleteFilter = (ind: number) => {
    let newFilters: Filter[] = [{ ...initialFilter }];
    if (filters.length !== 1) {
      newFilters = [...filters].filter((_, x) => x !== ind);
    }
    setFilters(newFilters);
  };
  const filtersUpdated = checkFiltersUpdated(filters, selectedFilters);

  const applyFilters = () => {
    if (filtersUpdated) {
      // Only update state with filters containing a value
      let filtersWithValues = filters.filter((f) => f.values.length);
      if (filtersWithValues.length === 0) {
        //we are resetting the filters
        filtersWithValues = [{ ...initialFilter }];
      }
      dispatch(updateFilters(filtersWithValues));
    }
  };

  const canDeleteFilters = filters.length > 1 || filters[0].values.length;

  return (
    <Grid
      container
      sx={{
        overflow: 'hidden',
      }}
    >
      <Grid
        item
        xs={6}
        style={{
          border: `1px solid ${color.gray400}`,
          borderRadius: '0 24px 0 0',
          display: openFilters ? 'block' : 'none',
          marginTop: '.5rem',
        }}
        sx={{ height: '100%', overflow: 'auto' }}
      >
        <Container
          sx={{
            width: '100%',
            marginBottom: '100px',
          }}
        >
          <Typography variant="h5" sx={{ padding: '24px 0' }}>
            {t('insights.filters_header')}
          </Typography>
          <Typography>{t('insights.filters_description')}</Typography>
          <Typography>{t('insights.filters_helper')}</Typography>
          <VSpacer />
          {
            <HStack
              style={{
                alignItems: 'center',
                paddingTop: '24px',
                justifyContent: 'space-between',
              }}
            >
              <Typography fontWeight={600}>
                {t('insights.filters_showing', {
                  count: visibleHighlights?.length ?? 0,
                })}
              </Typography>
              <Button
                onClick={applyFilters}
                style={{
                  color: color[filtersUpdated ? 'white' : 'foraPurple'],
                  backgroundColor:
                    color[filtersUpdated ? 'foraPurple' : 'gray300'],
                }}
                disabled={!filtersUpdated}
              >
                <Typography>{t('insights.filters_apply')}</Typography>
              </Button>
            </HStack>
          }
          {filters.map((filter, ind) => (
            <FilterInputRow
              index={ind}
              key={ind}
              filter={filter}
              updateFilter={updateFilter}
              deleteFilter={canDeleteFilters ? deleteFilter : undefined}
              filterJoin={filterJoin}
              setFilterJoin={(join) => {
                dispatch(updateFilterJoin(join));
              }}
            />
          ))}
          <Button
            icon={['far', 'plus']}
            onClick={addFilter}
            style={{
              color: color.foraPurple,
              boxShadow: 'none',
              border: 'none',
            }}
            disabled={filters.length >= 5}
          >
            <Typography>{t('insights.filters_add')}</Typography>
          </Button>
        </Container>
      </Grid>
      <Grid
        item
        xs={openFilters ? 6 : 12}
        sx={{ height: '100%', overflow: 'auto' }}
      >
        <Grid
          container
          item
          spacing={3}
          xs={8}
          style={{
            maxWidth: '1280px',
            width: '100%',
            flexBasis: 'unset',
            margin: '0 auto',
            paddingTop: '.5rem',
            overflowY: 'auto',
            boxSizing: 'border-box',
            justifyContent: 'center',
          }}
        >
          {visibleHighlights?.map((entry) => (
            <Grid
              item
              alignItems="stretch"
              lg={openFilters ? 12 : 6}
              key={entry.id}
              sx={{
                padding: '0 24px 24px 24px !important',
                maxWidth: { sm: '650px' },
              }}
            >
              <HighlightCard
                entry={entry}
                key={entry.id}
                onClick={() => onClick(entry)}
                onChangeCheck={onChangeCheck}
                checked={selectedHighlightIds.has(entry.id)}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

const FilterInputRow = ({
  index,
  filter,
  updateFilter,
  deleteFilter,
  filterJoin,
  setFilterJoin,
}: {
  index: number;
  filter: Filter;
  updateFilter: (ind: number, filter: Filter) => void;
  deleteFilter?: (ind: number) => void;
  filterJoin: FilterJoin;
  setFilterJoin: (join: FilterJoin) => void;
}) => {
  const {
    filterJoinLabel,
    filterCategoryLabel,
    filterOperationsLabel,
    filterValueLabel,
  } = useFilterLabels();
  const filterOptionMap = useFilterOptions();
  const filterOptions = filterOptionMap[filter.filter_name];
  const filterOperationMap = useFilterOperations();
  const filterOperations = filterOperationMap[filter.filter_name];
  const [_filter, setFilter] = useState(filter);

  // Only show filters that have options
  const availableFilters = Object.keys(filterOptionMap).filter(
    (key) => filterOptionMap[key as unknown as FilterName].length
  );

  const disableOptionsSelection = (operation: FilterOperation) =>
    valuelessOperations.includes(operation);

  const getOperationForNewCategory = (
    category: FilterName,
    operation: FilterOperation
  ): FilterOperation => {
    // If the new category has the existing operation, keep it, otherwise,
    // default to the first operation
    if (filterOperationMap[category].includes(operation)) {
      return operation;
    }
    return filterOperationMap[category][0];
  };

  const handleCategoryChange = (event: SelectChangeEvent<FilterName>) => {
    const newFilter: Filter = {
      operation: getOperationForNewCategory(
        event.target.value as unknown as FilterName,
        _filter.operation
      ),
      filter_name: event.target.value as unknown as FilterName,
      values: [], // value should be reset
    };
    setFilter(newFilter);
    updateFilter(index, newFilter);
  };

  const handleOperationChange = (event: SelectChangeEvent<FilterOperation>) => {
    let value = _filter.values;
    const operation = event.target.value as unknown as FilterOperation;
    if (disableOptionsSelection(operation)) {
      // If the change disables selection, clear the value field
      value = [];
    }
    const newFilter: Filter = {
      ..._filter,
      operation,
      values: value,
    };
    setFilter(newFilter);
    updateFilter(index, newFilter);
  };

  const handleValueChange = (event: SelectChangeEvent<(number | string)[]>) => {
    const value = event.target.value as unknown as number[];
    const newFilter: Filter = {
      ..._filter,
      values: value,
    };
    setFilter(newFilter);
    updateFilter(index, newFilter);
  };

  useEffect(() => {
    if (filter !== _filter) {
      // Ensure deletion is propagated
      setFilter(filter);
    }
  }, [filter]);

  return (
    <HStack sx={{ width: '100%', margin: '1rem 0' }}>
      {index !== 0 && (
        <FormControl
          size="small"
          sx={{ maxWidth: '85px', flex: 2, margin: '0 .25rem' }}
        >
          <Select
            sx={{
              padding: '0px !important',
              borderRadius: '8px',
            }}
            value={filterJoin}
            disabled={index !== 1}
            onChange={(event) => {
              setFilterJoin(event.target.value as unknown as FilterJoin);
            }}
            IconComponent={ExpandMoreRounded}
            MenuProps={{
              sx: {
                '& .MuiPaper-root': {
                  marginTop: '.5rem',
                  boxShadow: '0px 2px 6px 0px #00000040 !important',
                },
              },
            }}
          >
            {Object.keys(filterJoinLabel).map((key) => {
              return (
                <MenuItem value={key} key={key}>
                  {filterJoinLabel[key as unknown as FilterJoin]}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      )}
      <FormControl size="small" sx={{ flex: 3, margin: '0 .25rem' }}>
        <Select
          sx={{
            padding: '0px !important',
            borderRadius: '8px',
          }}
          value={_filter.filter_name}
          onChange={handleCategoryChange}
          IconComponent={ExpandMoreRounded}
          MenuProps={{
            sx: {
              '& .MuiPaper-root': {
                marginTop: '.5rem',
                boxShadow: '0px 2px 6px 0px #00000040 !important',
              },
            },
          }}
        >
          {availableFilters.map((key) => {
            return (
              <MenuItem value={key} key={key}>
                {filterCategoryLabel[key as unknown as FilterName]}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      <FormControl size="small" sx={{ flex: 2, margin: '0 .25rem' }}>
        <Select
          sx={{
            padding: '0px !important',
            borderRadius: '8px',
          }}
          value={_filter.operation}
          onChange={handleOperationChange}
          IconComponent={ExpandMoreRounded}
          MenuProps={{
            sx: {
              '& .MuiPaper-root': {
                marginTop: '.5rem',
                boxShadow: '0px 2px 6px 0px #00000040 !important',
              },
            },
          }}
        >
          {filterOperations.map((key) => {
            return (
              <MenuItem value={key} key={key}>
                {filterOperationsLabel[key as unknown as FilterOperation]}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      {!disableOptionsSelection(_filter.operation) && (
        <FormControl size="small" sx={{ flex: 4, margin: '0 .25rem' }}>
          <InputLabel
            id="filter-options"
            shrink={false}
            sx={{
              visibility: _filter.values.length ? 'hidden' : 'visible',
            }}
          >
            {filterValueLabel[_filter.filter_name]}
          </InputLabel>
          <Select
            id="filter-options"
            sx={{
              padding: '0px !important',
              borderRadius: '8px',
            }}
            value={_filter.values}
            onChange={handleValueChange}
            multiple
            disabled={disableOptionsSelection(_filter.operation)}
            IconComponent={ExpandMoreRounded}
            MenuProps={{
              sx: {
                '& .MuiPaper-root': {
                  marginTop: '.5rem',
                  boxShadow: '0px 2px 6px 0px #00000040 !important',
                },
              },
            }}
            renderValue={(selected) =>
              selected
                .map((i) => filterOptions.find((o) => o.id === i)?.name)
                .join(', ')
            }
          >
            {filterOptions.map((option) => (
              <MenuItem value={option.id} key={option.id}>
                <Checkbox checked={_filter.values.indexOf(option.id) > -1} />
                <ListItemText primary={option.name} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}

      {deleteFilter && (
        <Button
          color="plain"
          size="lg"
          icon={['far', 'trash-can']}
          style={{ margin: '0 .25rem', padding: '0px !important' }}
          onClick={() => {
            deleteFilter(index);
          }}
        />
      )}
    </HStack>
  );
};

export default FilterableHighlightGrid;
