import { useMemo, useState } from 'react';
import { useMeasure, useWindowSize } from 'react-use';
import EditIcon from '@mui/icons-material/Edit';
import {
  Backdrop,
  Chip,
  CircularProgress,
  FormControlLabel,
  Switch,
} from '@mui/material';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import color from 'src/assets/_util.scss';
import { HSpacer } from 'src/components/core/Spacer';
import { HStack, VStack } from 'src/components/core/Stack';
import { View } from 'src/components/core/View';
import {
  AIProvider,
  useAIContext,
} from 'src/components/Insights/Catalog/AIProvider';
import {
  CatalogPageProvider,
  Tab,
  useCatalogPageContext,
} from 'src/components/Insights/Catalog/CatalogPageProvider';
import CatalogSpeedDial from 'src/components/Insights/Catalog/CatalogSpeedDial';
import { CodebookProvider } from 'src/components/Insights/Catalog/CodebookProvider';
import { HighlightsPageProvider } from 'src/components/Insights/Catalog/HighlightsPageProvider';
import TabNavigation from 'src/components/Insights/Catalog/NavigationTabs';
import ModalDialog from 'src/components/Insights/Dialogs/ModalDialog';
import UpdateCatalogDialog from 'src/components/Insights/Dialogs/UpdateCatalogDialog';
import SearchField from 'src/components/Insights/Sidebar/SearchField';
import { NAVBAR_HEIGHT } from 'src/constants';
import { useFlag } from 'src/Providers/FlagProvider';
import NotFoundPage from '../Errors/NotFoundPage';
import DemographicAccordion from '../Sidebar/DemographicAccordion';
import InternalCodeAccordion from '../Sidebar/InternalCodeAccordion';
import StructuralCodeAccordion from '../Sidebar/StructuralCodeAccordion';
import ThematicCodeAccordion from '../Sidebar/ThematicCodeAccordion';
import { CodebookModern } from './CodebookModern';
import ConversationsPage from './ConversationsPage';
import { ConversationsPageProvider } from './ConversationsPageProvider';
import HighlightsPage from './HighlightsPage';
import OverviewPage from './OverviewPage';

export default function CatalogPage() {
  return (
    <CatalogPageProvider>
      <AIProvider>
        <CodebookProvider>
          <HighlightsPageProvider>
            <ConversationsPageProvider>
              <CatalogPageContent />
            </ConversationsPageProvider>
          </HighlightsPageProvider>
        </CodebookProvider>
      </AIProvider>
    </CatalogPageProvider>
  );
}

function CatalogPageContent() {
  const {
    activeTab,
    setActiveTab,
    catalog,
    isLoading,
    filtersAreLoading,
    modals,
    hasCodebook,
  } = useCatalogPageContext();
  const [scrollViewRef, { width: contentWidth }] = useMeasure();
  const hasNewCodebook = useFlag('ai_tagging');

  return (
    <HStack
      sx={{
        position: 'relative',
        flexBasis: '100vh',
        overflow: 'hidden',
        pt: `${NAVBAR_HEIGHT}px`,
      }}
    >
      <VStack sx={{ flexGrow: 1, background: color.white }}>
        <CatalogTitle />
        <TabNavigation {...{ activeTab, setActiveTab }} />

        <View
          ref={scrollViewRef}
          sx={{ display: 'flex', flexGrow: 1, overflow: 'hidden' }}
        >
          {{
            [Tab.Overview]: <OverviewPage />,
            [Tab.Conversations]: <ConversationsPage />,
            [Tab.Highlights]: <HighlightsPage />,
          }[activeTab] ?? <NotFoundPage />}
        </View>
      </VStack>

      {hasCodebook && (hasNewCodebook ? <CodebookModern /> : <CodeBook />)}
      {!catalog.community_id && <CatalogSpeedDial />}

      {modals?.map((modalProps) => (
        <ModalDialog
          {...modalProps}
          sx={{ width: contentWidth, ...modalProps.sx }}
        />
      ))}

      <Backdrop
        open={isLoading || filtersAreLoading}
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <CircularProgress />
      </Backdrop>
    </HStack>
  );
}

// SUBCOMPONENTS

function CatalogTitle() {
  const { catalog, showingHighlights, showModal, t } = useCatalogPageContext();
  const { aiOn, canTagWithAI, setAIOn } = useAIContext();

  const onClickEdit = () => showModal(<UpdateCatalogDialog />);

  return (
    <Grid item lg={6} xs={12} bgcolor={color.white}>
      <div style={{ display: 'flex', alignItems: 'baseline' }}>
        <Typography
          sx={{
            px: 3,
            py: '20px',
            fontWeight: 600,
            display: 'flex',
          }}
          variant="h5"
        >
          {catalog !== undefined ? catalog.title : '[untitled]'}
          <Tooltip arrow title={t('insights.edit_catalog')}>
            <IconButton onClick={onClickEdit} sx={{ py: 0 }}>
              <EditIcon />
            </IconButton>
          </Tooltip>
        </Typography>

        {catalog.community_id && (
          <Tooltip
            arrow
            data-testid="mobile-catalog-tooltip"
            placement="right"
            title={t('insights.mobile_catalog_tooltip')}
          >
            <Chip
              component="span"
              data-testid="mobile-catalog-chip"
              label={t('insights.catalog_community')}
              size="small"
              sx={{
                background: 'linear-gradient(to right, #4A6AD1, #6718CA)',
                color: '#fff',
                width: 'min-content',
              }}
              variant="outlined"
            />
          </Tooltip>
        )}

        <HSpacer grow />

        {showingHighlights && canTagWithAI && (
          <FormControlLabel
            checked={aiOn}
            control={<Switch color="primary" />}
            onChange={() => setAIOn((prev) => !prev)}
            label={t('insights.aiSuggestionsToggle')}
            labelPlacement="start"
            sx={{ marginRight: 3 }}
          />
        )}
      </div>
    </Grid>
  );
}

//TODO: delete me after insights restyle feature flag
function CodeBook() {
  const { showingHighlights } = useCatalogPageContext();
  const { width: screenWidth } = useWindowSize();
  const [codeFilter, setCodeFilter] = useState('');

  const minWidth = useMemo(
    () => Math.min(363, screenWidth * 0.25),
    [screenWidth]
  );

  return (
    <VStack
      sx={{
        overflowY: 'auto',
        padding: '12px 24px',
        backgroundColor: '#ffffff',
        flexShrink: 0,
        borderLeft: `1px solid ${color.gray400}`,
        minWidth,
      }}
    >
      <SearchField
        labelKey="insights.search_codes"
        onChange={setCodeFilter}
        sx={{ marginBottom: '16px' }}
      />

      {showingHighlights && (
        <>
          <StructuralCodeAccordion filter={codeFilter} />
          <ThematicCodeAccordion filter={codeFilter} />
          <InternalCodeAccordion filter={codeFilter} />
        </>
      )}
      <DemographicAccordion
        allowFiltering={!showingHighlights}
        filter={codeFilter}
      />
    </VStack>
  );
}
