/** Include all requests to the API here */
import moment from 'moment-timezone';
import {
  ArrayParam,
  BooleanParam,
  DateParam,
  encodeQueryParams,
  NumberParam,
  stringify,
  StringParam,
} from 'use-query-params';

import { ActivityMapData } from 'src/components/ActivityMap/ActivityMap';
import { getFiltersQuery } from 'src/components/Insights/utils/filters';
import { site } from 'src/config';
import { Role, User, UserRequestPayload } from 'src/types/auth';
import { Collection, CollectionDetail } from 'src/types/collection';
import {
  Annotation,
  Conversation,
  ConversationMetadata,
  Host,
  NormalizedTopicSnippet,
  RecentActivities,
  RecordingRoomMetadata,
  Snippet,
  Tag,
  TermTimings,
  Topics,
  TopicSnippet,
  UploadMetadata,
} from 'src/types/conversation';
import {
  BootstrapData,
  LegalContent,
  NormalizedEntities,
  NormalizedSnippetEntities,
  NormalizedTopicSnippetEntities,
  ServerError,
} from 'src/types/core';
import {
  Community,
  CommunityMember,
  CommunityMembersResult,
} from 'src/types/forum';
import {
  AITaggingResponse,
  Catalog,
  CatalogDetailsResponse,
  CatalogFiltersResponse,
  CatalogsEntities,
  Code,
  Codebook,
  ConversationsEntities,
  Demographic,
  EntriesEntities,
  EntriesRequest,
  Entry,
  ImportedParticipantRow,
  NewCatalogResponse,
  Participant,
} from 'src/types/insights';
import { OrganizationMetadata } from 'src/types/organization';
import { RegisteredConversation, Shift } from 'src/types/registration';
import dateFormatter from 'src/util/date';
import { del, get, patch, post, postMultipart, put, putFile } from './helpers';

export function transformUser<T extends User | CommunityMember>(user: T): T {
  user.lvn_terms_agreed = user.lvn_terms_agreed
    ? dateFormatter.timeToMoment((user as any).lvn_terms_agreed).utc()
    : undefined;
  user.date_joined = dateFormatter.timeToMoment((user as any).date_joined);
  user.guardian_date_agree = user.guardian_date_agree
    ? dateFormatter.timeToMoment((user as any).guardian_date_agree)
    : undefined;
  user.last_access = user.last_access
    ? dateFormatter.timeToMoment((user as any).last_access).utc()
    : undefined;
  user.last_login = user.last_login
    ? dateFormatter.timeToMoment((user as any).last_login).utc()
    : undefined;
  return user;
}

function transformConversation(conversation: Conversation): Conversation {
  // transform dates via Moment
  conversation.time = dateFormatter.timeToMoment(
    (conversation as any).start_time
  );
  (conversation as any).start_time = dateFormatter.timeToMoment(
    (conversation as any).start_time
  );
  (conversation as any).end_time = dateFormatter.timeToMoment(
    (conversation as any).end_time
  );
  conversation.post_time = dateFormatter.timeToMoment(
    (conversation as any)._post_time
  );

  return conversation;
}

export function transformAccountsServerError(err: Error): ServerError {
  try {
    const errMsg = JSON.parse(err.message);
    // set the status message as the first error
    const keys = Object.keys(errMsg.data);
    const msg = errMsg.data[keys[0]][0];
    return { status: 'fail', message: msg, attribute: keys[0] };
  } catch {
    return { status: 'fail', message: 'An unexpected error occurred' };
  }
}

export interface ConversationsFilters {
  currentUser: boolean;
  hostId: number;
  limit: number;
  page: number;
  collectionIds: string[];
  forumIds: string[];
  isDraft: boolean;
  orderByPostTime: boolean;
  startDate: Date;
  endDate: Date;
  organizationIds: string[];
  languageCodes: string[];
  privacyLevels: string[];
}

function transformSnippet(snippet: Snippet): Snippet {
  snippet.time = moment(snippet.time);
  return snippet;
}

function transformAnnotation(annotation: Annotation): Annotation {
  annotation.created_at = dateFormatter.timeToMoment(
    annotation.created_at as any
  );

  return annotation;
}

/**
 * Transforms normalized data to work in the front-end.
 * - converts dates to Moment objects
 *
 * @param data A normalized response { order, entities }
 */
function transformNormalizedEntitiesResponse(
  data: NormalizedEntities
): NormalizedEntities {
  const { conversations = {}, annotations = {}, snippets = {} } = data.entities;

  for (const conversationId in conversations) {
    const conversation = conversations[conversationId];
    transformConversation(conversation);
  }

  for (const annotationId in annotations) {
    const annotation = annotations[annotationId];
    transformAnnotation(annotation);
  }

  for (const snippetId in snippets) {
    const snippet = snippets[snippetId];
    transformSnippet(snippet);
  }

  if (process.env.NODE_ENV === 'development') {
    console.log('transformed normalized response data', data);
  }

  return data;
}

/**
 * Load a list of conversations
 */
export function getConversations(
  user: User,
  filters: Partial<ConversationsFilters> = {}
): Promise<NormalizedEntities> {
  const queryParams = encodeQueryParams(
    {
      host_id: NumberParam,
      limit: NumberParam,
      page: NumberParam,
      collection_ids: ArrayParam,
      forum_ids: ArrayParam,
      is_draft: BooleanParam,
      order_by_post_time: BooleanParam,
      start_date: DateParam,
      end_date: DateParam,
      organization_ids: ArrayParam,
      language_codes: ArrayParam,
      privacy_levels: ArrayParam,
    },
    {
      host_id: filters.currentUser != null ? user.id : filters.hostId,
      limit: filters.limit,
      page: filters.page,
      collection_ids: filters.collectionIds,
      forum_ids: filters.forumIds,
      is_draft: filters.isDraft != null ? filters.isDraft : undefined,
      order_by_post_time:
        filters.orderByPostTime != null ? filters.orderByPostTime : undefined,
      start_date: filters.startDate,
      end_date: filters.endDate,
      organization_ids: filters.organizationIds,
      language_codes: filters.languageCodes,
      privacy_levels: filters.privacyLevels,
    }
  );

  return get(`${site.apiUrl}/api/conversations/?${stringify(queryParams)}`)
    .then((results) => results.data)
    .then((data) => transformNormalizedEntitiesResponse(data));
}

export interface SearchFilters {
  searchQuery: string;
  currentUser: boolean;
  hostId: number;
  limit: number;
  page: number;
  collectionIds?: number[];
  isDraft: boolean;
  orderByPostTime: boolean;
}
/**
 * Load a list of conversations
 */
export function searchConversations(
  user: User,
  filters: Partial<SearchFilters>
): Promise<NormalizedEntities> {
  const queryParams = encodeQueryParams(
    {
      q: StringParam,
      host_id: NumberParam,
      limit: NumberParam,
      page: NumberParam,
      collection_ids: ArrayParam,
      is_draft: BooleanParam,
      order_by_post_time: BooleanParam,
    },
    {
      q: filters.searchQuery,
      host_id: filters.currentUser != null ? user.id : filters.hostId,
      limit: filters.limit,
      page: filters.page,
      collection_ids: filters.collectionIds
        ? filters.collectionIds.map((c) => c.toString())
        : undefined,
      is_draft: filters.isDraft != null ? filters.isDraft : undefined,
      order_by_post_time:
        filters.orderByPostTime != null ? filters.orderByPostTime : undefined,
    }
  );

  return get(
    `${site.apiUrl}/api/conversations/search/?${stringify(queryParams)}`
  )
    .then((results) => results.data)
    .then((data) => transformNormalizedEntitiesResponse(data));
}

/**
 * Loads details about a conversation
 */
export function getConversation(
  conversationId: Conversation['id']
): Promise<NormalizedEntities> {
  return get(`${site.apiUrl}/api/conversations/detail/${conversationId}`)
    .then((results) => results.data)
    .then((data) => transformNormalizedEntitiesResponse(data));
}

/**
 * Loads top terms for a conversation filtered by topic
 */
export function getConversationTopTerms(
  conversationId: Conversation['id'],
  topicCode: string
): Promise<TermTimings[]> {
  return get(
    `${site.apiUrl}/api/conversations/detail/${conversationId}?topic_code=${topicCode}`
  ).then((results) => {
    return results.data.entities.conversations[conversationId].top_terms;
  });
}

////////////////////////////////////////////////////////////////
//  Conversation highlight management
////////////////////////////////////////////////////////////////
/**
 * Save a new annotation to the database. Result comes back with an ID set.
 */
export function saveNewAnnotation(
  user: User,
  annotationToSave: Partial<Annotation>
): Promise<Annotation> {
  const userFullName =
    `${user.first_name} ${user.last_name}`.trim() || user.email.split('@')[0];

  // modify to have extended fields
  const expandedHighlightToSave = {
    ...annotationToSave,
    id: '__unsaved_annotation_' + annotationToSave.creation_tag,
    created_at: moment(),
    user_id: user.id,
    user_name: userFullName,
    by_current_user: true,
    annotation_type: annotationToSave.annotation_type || 'highlight_community',
  };

  return post(`${site.apiUrl}/api/annotations/`, null, expandedHighlightToSave)
    .then((results) => results.data)
    .then((data) => transformAnnotation(data))
    .then((savedAnnotation) => {
      // copy fields over so existing object acts identical to the saved one
      // needed for delete to have the proper ID
      Object.assign(annotationToSave, savedAnnotation);
      return savedAnnotation;
    });
}

////////////////////////////////////////////////////////////////
// Highlights
////////////////////////////////////////////////////////////////

export interface HighlightsFilters {
  id: number;
  currentUser: boolean;
  starred: boolean;
  limit: number;
  page: number;
  collectionIds: string[];
  randomize: boolean;
  startDate: Date;
  endDate: Date;
  topicCode: string;
  excludeUserPrivate: boolean;
  annotationTypes: string[];
  minTopicProb: number;
  searchQuery?: string | undefined;
  starredAndUser?: boolean;
  tags?: string[];
  privacyLevels: string[];
}

export function getHighlights(
  user: User,
  filters?: Partial<HighlightsFilters>
): Promise<NormalizedEntities> {
  let queryParams = {};

  if (filters) {
    queryParams = encodeQueryParams(
      {
        id: NumberParam,
        user_id: NumberParam,
        starred: BooleanParam,
        limit: NumberParam,
        page: NumberParam,
        annotation_type: ArrayParam,
        collection_ids: ArrayParam,
        randomize: BooleanParam,
        start_date: DateParam,
        end_date: DateParam,
        topic_code: StringParam,
        exclude_user_private: BooleanParam,
        min_topic_prob: NumberParam,
        q: StringParam, // search query
        starred_and_user: BooleanParam, // all starred and all user highlights, not starred user highlights
        tags: ArrayParam,
        privacy_levels: ArrayParam,
      },
      {
        id: filters.id,
        user_id: filters.currentUser ? user.id : undefined,
        starred: filters.starred,
        limit: filters.limit,
        page: filters.page,
        // does not get auto highlights by default, only human-made highlights
        annotation_type: filters.annotationTypes || [
          'highlight_community',
          'highlight_curated',
        ],
        collection_ids: filters.collectionIds
          ? filters.collectionIds.map((c) => c.toString())
          : undefined,
        randomize: filters.randomize,
        start_date: filters.startDate,
        end_date: filters.endDate,
        topic_code: filters.topicCode,
        exclude_user_private: filters.excludeUserPrivate,
        min_topic_prob: filters.minTopicProb,
        q: filters.searchQuery,
        starred_and_user: filters.starredAndUser,
        tags: filters.tags,
        privacy_levels: filters.privacyLevels,
      }
    );
  }

  // note: sending the queryParams object directly sends {'annotation_type': 'highlight_community,highlight_curated'}
  // while stringifying the queryParams sends {'annotation_type': ['highlight_community', 'highlight_curated']}
  return get(`${site.apiUrl}/api/annotations/?${stringify(queryParams)}`)
    .then((results) => results.data)
    .then((data) => transformNormalizedEntitiesResponse(data));
}

/**
 * Delete a highlight permanently
 */
export function deleteHighlight(highlightId: Annotation['id']): Promise<void> {
  return del(`${site.apiUrl}/api/annotations/${highlightId}`);
}

/**
 * Edit a highlight
 */
export function editHighlight(
  highlightId: Annotation['id'],
  changes: Partial<Annotation>
): Promise<Annotation> {
  return put(`${site.apiUrl}/api/annotations/${highlightId}`, null, changes);
}

/**
 * Star a highlight
 */
export function starHighlight(highlightId: Annotation['id']): Promise<void> {
  return post(`${site.apiUrl}/api/annotations/starred/`, null, {
    annotation_id: highlightId,
  }).then((results) => results.data);
}

/**
 * Unstar a highlight
 */
export function unstarHighlight(highlightId: Annotation['id']): Promise<void> {
  return del(`${site.apiUrl}/api/annotations/starred/`, {
    annotation_id: `${highlightId}`,
  }).then((results) => results.data);
}

/**
 * Edit a conversation
 */
export function editConversation(
  conversationId: Conversation['id'],
  changes: Partial<Conversation>
): Promise<Partial<Conversation>> {
  const flatChanges: any = { ...changes };

  // flatten nested location object
  if (flatChanges.location && flatChanges.location.name) {
    flatChanges.location = flatChanges.location.name;
  }

  return put(
    `${site.apiUrl}/api/conversations/detail/${conversationId}`,
    null,
    flatChanges
  ).then((results) => results.data);
}

////////////////////////////////////////////////////////////////
// Tags
////////////////////////////////////////////////////////////////

export function getTags(prefix: string | undefined): Promise<Tag[]> {
  const queryParams = prefix ? { prefix } : null;
  return get(`${site.apiUrl}/api/tags/`, queryParams).then(
    (results) => results.data
  );
}

////////////////////////////////////////////////////////////////
// Settings
////////////////////////////////////////////////////////////////

export function editUser(
  userId: User['id'],
  changes: Partial<UserRequestPayload>
): Promise<Partial<User>> {
  if (changes.new_profile_image) {
    const formData = new FormData();
    formData.append('profile_image', changes.new_profile_image);
    return putFile(`${site.apiUrl}/api/users/${userId}`, formData).then(
      (results) => results.data
    );
  } else {
    return put(`${site.apiUrl}/api/users/${userId}`, null, changes).then(
      (results) => results.data
    );
  }
}

////////////////////////////////////////////////////////////////
// Registration
////////////////////////////////////////////////////////////////
export function createUserAccount(
  user: Partial<UserRequestPayload>
): Promise<User> {
  return post(`${site.apiUrl}/api/users/register`, null, user).then(
    (results) => results.data
  );
}

////////////////////////////////////////////////////////////////
// Collections
////////////////////////////////////////////////////////////////
export function getCollectionDetail(
  collectionId: number
): Promise<CollectionDetail> {
  return get(`${site.apiUrl}/api/collections/detail/${collectionId}`).then(
    (results) => results.data
  );
}

export function editCollectionDetail(
  collectionId: CollectionDetail['id'],
  changes: Partial<CollectionDetail>
): Promise<Pick<CollectionDetail, 'id' | 'description' | 'title'>> {
  return put(
    `${site.apiUrl}/api/collections/detail/${collectionId}`,
    null,
    changes
  ).then((results) => results.data);
}

export function getCollections(options: {
  organizationId?: number;
  isManager?: boolean;
}): Promise<Collection[]> {
  const queryParams = encodeQueryParams(
    {
      is_manager: StringParam,
      organization_id: NumberParam,
    },
    {
      is_manager: options.isManager ? 'true' : undefined,
      organization_id: options.organizationId,
    }
  );
  return get(`${site.apiUrl}/api/collections/?${stringify(queryParams)}`).then(
    (results) => results.data
  );
}

export function deleteCollection(
  collectionId: CollectionDetail['id']
): Promise<void> {
  return del(`${site.apiUrl}/api/collections/detail/${collectionId}`);
}

export function createCollection(
  collection: Partial<Collection>
): Promise<CollectionDetail> {
  return post(`${site.apiUrl}/api/collections/`, null, collection).then(
    (results) => results.data
  );
}

////////////////////////////////////////////////////////////////
// Communities
////////////////////////////////////////////////////////////////

export function getCommunities(options: {
  organizationId?: number;
}): Promise<Community[]> {
  const queryParams = encodeQueryParams(
    {
      organization_id: NumberParam,
    },
    {
      organization_id: options.organizationId,
    }
  );
  return get(`${site.apiUrl}/api/communities/?${stringify(queryParams)}`).then(
    (results) => results.data
  );
}

export function getCommunityMembers(options: {
  communityId?: number;
}): Promise<CommunityMembersResult> {
  return get(`${site.apiUrl}/api/communities/${options.communityId}`).then(
    (results) => {
      const res = results.data;
      return {
        community: res.community,

        // TODO: remove when we can get the backend fix of returning empty arrays
        // when the admin user doesn't have read community user permissions
        community_users: (res.community_users || []).map(transformUser),
        non_community_users: (res.non_community_users || []).map(transformUser),
      } as CommunityMembersResult;
    }
  );
}

export function updateCommunityDetails(
  communityId: Community['id'],
  changes: Partial<Community>
): Promise<Community> {
  return put(
    `${site.apiUrl}/api/communities/${communityId}`,
    undefined,
    changes
  ).then((results) => results.data);
}

export function removeCommunityMember(
  userId: number,
  communityId: number
): Promise<{ user_id: number; community_id: number }> {
  const body = { community_id: communityId };
  return del(
    `${site.apiUrl}/api/communities/${communityId}/member/${userId}`,
    undefined,
    body
  ).then((results) => results.data);
}

export function addCommunityMember(
  userId: number,
  communityId: number
): Promise<{ user_id: number; community_id: number }> {
  const body = { community_id: communityId };

  return post(
    `${site.apiUrl}/api/communities/${communityId}/member/${userId}`,
    undefined,
    body
  ).then((results) => results.data);
}
////////////////////////////////////////////////////////////////
// Topics
////////////////////////////////////////////////////////////////

export function getTopics(collectionId: number): Promise<Topics> {
  const queryParams = { collection_id: `${collectionId}` };
  return get(`${site.apiUrl}/api/topics/`, queryParams).then(
    (results) => results.data
  );
}

export interface TopicSnippetFilters {
  limit: number;
  page: number;
  topicId: number;
  collectionId: number;
  keywords?: string[];
  relatedTopicId?: number;
}
export function getTopicSnippets(
  filters: TopicSnippetFilters
): Promise<NormalizedTopicSnippetEntities> {
  const queryParams = encodeQueryParams(
    {
      limit: NumberParam,
      page: NumberParam,
      topic_id: NumberParam,
      collection_id: NumberParam,
      keywords: ArrayParam,
      related_topic_id: NumberParam,
    },
    {
      limit: filters.limit,
      page: filters.page,
      topic_id: filters.topicId,
      collection_id: filters.collectionId,
      keywords: filters.keywords,
      related_topic_id: filters.relatedTopicId,
    }
  );

  return get(`${site.apiUrl}/api/topic/snippets/?${stringify(queryParams)}`)
    .then((results) => results.data)
    .then((results) => transformNormalizedTopicSnippetEntities(results));
}

function transformNormalizedTopicSnippetEntities(
  entities: NormalizedTopicSnippetEntities
) {
  let topicSnippets = entities.entities.topic_snippets;
  // empty result
  if (topicSnippets == null) {
    topicSnippets = [];
  }
  const hosts = entities.entities.hosts;
  const keys = Object.keys(topicSnippets).map((k) => Number(k));
  keys.forEach((k) => {
    topicSnippets[k] = transformTopicSnippet(topicSnippets[k], hosts);
  });

  return entities;
}

function transformTopicSnippet(
  snippet: NormalizedTopicSnippet,
  hosts: { [key: number]: Host }
): TopicSnippet {
  const conversation = snippet.conversation;
  const newConversationData = {
    // transform dates via Moment
    time: dateFormatter.timeToMoment((conversation as any).start_time),
    start_time: dateFormatter.timeToMoment((conversation as any).start_time),
    end_time: dateFormatter.timeToMoment((conversation as any).end_time),
    host: hosts[conversation.host_id],
  };
  const newConversation = Object.assign(conversation, newConversationData);

  return Object.assign(snippet, { conversation: newConversation });
}

////////////////////////////////////////////////////////////////
// Recent Activity
////////////////////////////////////////////////////////////////
const transformRecentActivity = (recentActivities: RecentActivities) => {
  // need to convert time string to moment
  recentActivities.activities.forEach((activity) => {
    activity.created_at = dateFormatter.timeToMoment(
      (activity as any).created_at
    );
  });

  return recentActivities;
};

interface RecentActivityFilters {
  limit: number;
  page: number;
  collectionIds: number[];
}
export function getRecentActivity(
  filters: RecentActivityFilters
): Promise<RecentActivities> {
  const queryParams = {
    limit: filters.limit.toString(),
    page: filters.page.toString(),
    collection_ids: filters.collectionIds.toString(),
  };

  return get(`${site.apiUrl}/api/recent-activity/`, queryParams)
    .then((results) => results.data)
    .then((data) => transformRecentActivity(data));
}

////////////////////////////////////////////////////////////////
// Bootstrap Data
////////////////////////////////////////////////////////////////
// only used in non-prod envs
// prod data transformed in auth-slice.ts
export function getBootstrapData(): Promise<BootstrapData> {
  return get(`${site.apiUrl}/api/bootstrap/`)
    .then((results) => results.data)
    .then((data) => {
      data.user.date_joined = dateFormatter.timeToMoment(data.user.date_joined);
      data.user.lvn_terms_agreed =
        data.user.lvn_terms_agreed != null
          ? dateFormatter.timeToMoment(data.user.lvn_terms_agreed).utc()
          : null;
      return data;
    });
}

////////////////////////////////////////////////////////////////
// Activity Map
////////////////////////////////////////////////////////////////
export function getActivityMap(
  collectionId: number
): Promise<ActivityMapData[]> {
  return get(`${site.apiUrl}/api/activity-map/`, {
    collection_id: `${collectionId}`,
  })
    .then((results) => results.data)
    .then((results) => {
      results.map_data.forEach((d: any) => {
        d.latest_activity = moment(d.latest_activity);
        d.conversation_time = moment(d.conversation_time);
      });

      return results.map_data as ActivityMapData[];
    });
}

////////////////////////////////////////////////////////////////
// Conversation upload
////////////////////////////////////////////////////////////////

/**
 * Get the metadata needed to populate the audio upload form
 */
export function getUploadFormMetadata(): Promise<UploadMetadata> {
  return get(`${site.apiUrl}/api/conversation-audio/`).then(
    (results) => results.data
  );
}

/**
 * Upload a conversation given metadata and an audio file
 */
export function uploadConversation(
  metadata: ConversationMetadata,
  audio: File
): Promise<ConversationMetadata> {
  const {
    conversationTitle,
    collectionId,
    numberOfParticipants,
    startDatetime,
    sourceType,
    userId,
    languageCode,
    lngLat,
    trimmedAudioStartTime,
    trimmedAudioEndTime,
  } = metadata;

  // Send trim times only if they exist
  const trim_times = trimmedAudioStartTime
    ? [[trimmedAudioStartTime, trimmedAudioEndTime]]
    : [];

  const formattedMetadata = {
    title: conversationTitle,
    environment: 'production',
    source_id: 'lvn_upload',
    language_codes: [languageCode],
    geo: lngLat?.includes('') ? undefined : lngLat,
    collection_id: collectionId,
    schema_version: '1.0',
    host_id: userId, // host is current user
    start_time: startDatetime,
    source_type: sourceType,
    cross_pollination: [], // not collected in leaven
    num_participants: numberOfParticipants,
    trim_times,
  };

  // append both the metadata and the audio as files to FormData
  const formData = new FormData();
  formData.append(
    'metadata',
    new File([JSON.stringify(formattedMetadata)], 'metadata.json')
  );
  formData.append('audio_file', audio);

  return postMultipart(`${site.apiUrl}/api/conversation-audio`, formData).then(
    (results) => results.data
  );
}

////////////////////////////////////////////////////////////////
// Conversation record
////////////////////////////////////////////////////////////////

export function startRecordingRoom(
  metadata: any
): Promise<RecordingRoomMetadata> {
  return post(
    `${site.apiUrl}/api/record_conversation`,
    undefined,
    metadata
  ).then((results) => results.data);
}
interface UpdateRecordingPayload {
  user_id: number;
  action: string;
  room_name: string;
  room_sid: string;
  collection_id: string;
}

export function updateRecordingRoom(
  payload: UpdateRecordingPayload
): Promise<RecordingRoomMetadata> {
  const { user_id, action, room_name, room_sid, collection_id } = payload;
  return put(`${site.apiUrl}/api/record_conversation`, null, {
    user_id,
    action,
    room_name,
    room_sid,
    collection_id,
  }).then((results) => results.data);
}

export function getRecordingRoomStatus(room_sid: string) {
  const queryParams = {
    room_sid: room_sid,
  };
  return get(`${site.apiUrl}/api/record_conversation`, queryParams).then(
    (results) => {
      return results.data;
    }
  );
}

////////////////////////////////////////////////////////////////
// Organizations
////////////////////////////////////////////////////////////////
/**
 * Get organization details for orgs the user has access to
 */
export function getOrganizations(): Promise<OrganizationMetadata[]> {
  return get(`${site.apiUrl}/api/organizations/`).then(
    (results) => results.data
  );
}

////////////////////////////////////////////////////////////////
// Registration
////////////////////////////////////////////////////////////////
export function getUserUpcomingConversations(): Promise<
  RegisteredConversation[]
> {
  return get(`${site.apiUrl}/api/shifts/hours/`).then((results) => {
    const hours: RegisteredConversation[] = results.data.hours;
    return hours;
  });
}

export function editUserRegistration(
  id: RegisteredConversation['id'],
  changes: Partial<RegisteredConversation>
): Promise<Partial<RegisteredConversation>> {
  return put(`${site.apiUrl}/api/shifts/hours/${id}`, null, changes).then(
    (results) => results.data
  );
}

// Get information about a single shift
export function getShift(id: Shift['id']): Promise<Shift> {
  return get(`${site.apiUrl}/api/shifts?shift_id=${id}`).then(
    (results) => results.data.shifts[0]
  );
}

// Register a user for a conversation (only used for existing users)
export function registerUserForShift(id: Shift['id']): Promise<void> {
  return post(`${site.apiUrl}/api/shifts/hours`, null, {
    shift_id: id,
  });
}

////////////////////////////////////////////////////////////////
// Legal
////////////////////////////////////////////////////////////////
export function getLegalContent(): Promise<LegalContent> {
  return get(`${site.apiUrl}/api/legal/`).then((results) => results.data);
}

export function getUserFromToken(uidb64: string, token: string): Promise<User> {
  const queryParams = { uidb64, token };
  return get(`${site.apiUrl}/api/token/`, queryParams).then(
    (results) => results.data
  );
}

/////////////////////////////////////////////////////////////
// Admin
/////////////////////////////////////////////////////////////
export function getUsersInOrganization(
  organizationId: number
): Promise<User[]> {
  const queryParams = encodeQueryParams(
    { organization_id: NumberParam },
    { organization_id: organizationId }
  );
  return get(`${site.apiUrl}/api/users?${stringify(queryParams)}`).then(
    (results) => results.data.map((user: User) => transformUser(user))
  );
}

export function getUserInOrganizationById(
  organizationId: number,
  userId: number
): Promise<User> {
  const queryParams = encodeQueryParams(
    { organization_id: NumberParam },
    { organization_id: organizationId }
  );
  return get(
    `${site.apiUrl}/api/users/${userId}?${stringify(queryParams)}`
  ).then((results) => transformUser(results.data));
}

export function postUserCreation(
  userDetails: Partial<UserRequestPayload>,
  organization_id: number
): Promise<User> {
  return post(`${site.apiUrl}/api/users`, undefined, {
    ...userDetails,
    organization_id,
  }).then((results) => transformUser(results.data));
}

export function postLinkUserToOrganization(
  organization_id: number,
  email: string,
  user_id: number,
  roles: Role[]
): Promise<{ user_id: number; organization_id: number }> {
  return post(
    `${site.apiUrl}/api/accounts/${user_id}/organizations`,
    undefined,
    {
      email,
      organization_id,
      roles,
    }
  ).then((results) => results.data);
}

export function putUserEdit(
  userUpdates: Pick<User, 'id'> & Partial<UserRequestPayload>
): Promise<User> {
  return put(
    `${site.apiUrl}/api/users/${userUpdates.id}`,
    undefined,
    userUpdates
  ).then((results) => transformUser(results.data));
}

export function removeUserFromOrganization(
  userId: number,
  organizationId: number
): Promise<{ user_id: number; organization_id: number }> {
  const body = { organization_id: organizationId };
  return del(
    `${site.apiUrl}/api/accounts/${userId}/organizations`,
    undefined,
    body
  ).then((results) => results.data);
}

export interface RolesPayload {
  role_types: string[];
  organizationId?: number;
  collectionId?: number;
  communityId?: number;
  catalogId?: number;
}
export function postUserRoles(
  userId: number,
  payload: RolesPayload
): Promise<void> {
  return post(`/api/accounts/${userId}/roles`, undefined, {
    role_types: payload.role_types,
    organization_id: payload.organizationId,
    collection_id: payload.collectionId,
    community_id: payload.communityId,
    catalog_id: payload.catalogId,
  });
}

export function putUserRoles(
  userId: number,
  payload: RolesPayload
): Promise<void> {
  return put(`${site.apiUrl}/api/accounts/${userId}/roles`, undefined, {
    role_types: payload.role_types,
    organization_id: payload.organizationId,
    collection_id: payload.collectionId,
    community_id: payload.communityId,
    catalog_id: payload.catalogId,
  });
}

export function deleteUserRoles(
  userId: number,
  payload: RolesPayload
): Promise<void> {
  return del(`/api/accounts/${userId}/roles`, undefined, {
    role_types: payload.role_types,
    organization_id: payload.organizationId,
    collection_id: payload.collectionId,
    community_id: payload.communityId,
    catalog_id: payload.catalogId,
  });
}

export function sendActivationEmail(
  organization_id: number,
  user_id: number
): Promise<void> {
  return post(`${site.apiUrl}/api/accounts/email/${user_id}`, undefined, {
    organization_id,
    email_type: 'organization',
  });
}

/////////////////////////////////////////////////////////////
// Transcript editing
/////////////////////////////////////////////////////////////

interface TranscriptEditPayload {
  version: number;
  conversationId: number;
  snippets: Partial<Snippet>[];
}

interface TranscriptRedactPayload {
  version: number;
  conversationId: number;
  snippets: Partial<Snippet>[];
  audio_start_offset: number;
  audio_end_offset: number;
}

export function saveTranscriptEdit(
  payload: TranscriptEditPayload
): Promise<NormalizedSnippetEntities> {
  const { version, conversationId, snippets } = payload;
  return put(
    `${site.apiUrl}/api/transcript/edit/${conversationId}`,
    undefined,
    { snippets, version }
  ).then((res) => res.data);
}

export function resetTranscript(
  payload: Omit<TranscriptEditPayload, 'snippets'>
): Promise<NormalizedSnippetEntities> {
  const { version, conversationId } = payload;
  return put(
    `${site.apiUrl}/api/transcript/edit/${conversationId}`,
    undefined,
    { reset: true, version }
  ).then((res) => res.data);
}

export function legacyRedactionTranscriptEdit(
  payload: TranscriptEditPayload
): Promise<NormalizedSnippetEntities> {
  const { version, conversationId, snippets } = payload;
  return put(
    `${site.apiUrl}/api/transcript/edit/${conversationId}`,
    undefined,
    { redaction: true, version, snippets }
  ).then((res) => res.data);
}

export function redactionTranscriptEdit(
  payload: TranscriptRedactPayload
): Promise<NormalizedSnippetEntities> {
  const {
    version,
    conversationId,
    snippets,
    audio_start_offset,
    audio_end_offset,
  } = payload;
  return put(
    `${site.apiUrl}/api/transcript/redact/${conversationId}`,
    undefined,
    {
      version,
      snippets,
      redaction_start_offset: audio_start_offset,
      redaction_end_offset: audio_end_offset,
    }
  ).then((res) => res.data);
}

export function getTranscript(conversationId: number): Promise<Response> {
  return fetch(
    `${site.apiUrl}/api/transcript/download/conversation_id/${conversationId}`,
    {
      method: 'GET',
      credentials: 'include',
    }
  ).then((response) => {
    return response;
  });
}

//////////////////////////////////////////
// INSIGHTS
/////////////////////////////////////////

export function getCatalogs(): Promise<CatalogsEntities> {
  return get(`${site.apiUrl}/api/insights/catalogs`).then(
    (results) => results.data.entities
  );
}

export function createCatalog(
  catalog: Omit<Catalog, 'id'>
): Promise<NewCatalogResponse> {
  const { organization_id, title, description } = catalog;
  return post(`${site.apiUrl}/api/insights/catalogs`, null, {
    organization_id: organization_id,
    title: title,
    description: description,
  }).then((response) => response.data);
}

export function addConversationsToCatalog(
  catalogId: Catalog['id'],
  conversationIds: Conversation['id'][]
): Promise<void> {
  return post(`/api/insights/catalogs/${catalogId}/conversations`, undefined, {
    conversation_ids: conversationIds,
  });
}

export function assignCoding(
  codeId: Code['id'],
  entryId: Entry['id']
): Promise<void> {
  return post('/api/insights/codings', undefined, {
    code_id: codeId,
    catalog_entry_id: entryId,
  });
}

export function assignPrimarySpeaker(
  catalogId: Catalog['id'],
  entryId: Entry['id'],
  primarySpeakerId: Entry['primary_participant_id']
): Promise<void> {
  return put(`/api/insights/catalogs/${catalogId}/entries`, undefined, {
    catalog_entry_id: entryId,
    participant_id: primarySpeakerId,
  });
}

export function assignDemographic(
  demographicId: Demographic['id'],
  participantId: Participant['id']
): Promise<void> {
  return post('/api/insights/demographics/participants', undefined, {
    demographic_id: demographicId,
    participant_id: participantId,
  });
}

export function uploadDemographics(
  catalog_id: Catalog['id'],
  demographics: ImportedParticipantRow[]
): Promise<void> {
  return put(`/api/insights/catalogs/${catalog_id}/demographics`, undefined, {
    participants_demographics: demographics,
  });
}

export function createCode(code: Omit<Code, 'id'>): Promise<Code> {
  return post('/api/insights/codes', undefined, {
    codebook_id: code.codebook_id,
    color: code.color,
    description: code.description,
    name: code.name,
    code_type: code.code_type,
    ...(code.parent_id !== -1 ? { parent_id: code.parent_id } : {}),
  }).then((response) => response.data);
}

export function createDemographic(
  demographic: Omit<Demographic, 'id'>
): Promise<Demographic> {
  return post(
    `/api/insights/catalogs/${demographic.catalog_id}/demographics`,
    undefined,
    {
      color: demographic.color,
      description: demographic.description,
      name: demographic.name,
      ...(demographic.parent_id !== -1
        ? { parent_id: demographic.parent_id }
        : {}),
    }
  ).then((response) => response.data);
}

export function deleteCatalog(catalogId: Catalog['id']): Promise<void> {
  return del(`/api/insights/catalogs/${catalogId}`);
}

export function deleteCode(codeId: Code['id']): Promise<void> {
  return del(`/api/insights/codes/${codeId}`);
}

export function deleteDemographic(
  demographicId: Demographic['id']
): Promise<void> {
  return del(`/api/insights/demographics/${demographicId}`);
}

export function deleteConversationsFromCatalog(
  catalogId: Catalog['id'],
  conversationIds: Conversation['id'][]
): Promise<void> {
  return del(`/api/insights/catalogs/${catalogId}/conversations`, undefined, {
    conversation_ids: conversationIds,
  });
}

export function getCatalog(
  catalogId: Catalog['id']
): Promise<CatalogDetailsResponse> {
  return get(`/api/insights/catalogs/${catalogId}`).then((response) => {
    return response.data;
  });
}

export function getCatalogFilters(
  catalogId: Catalog['id']
): Promise<CatalogFiltersResponse> {
  return get(`/api/insights/catalogs/${catalogId}/filters`).then((response) => {
    return response.data;
  });
}

export function getCodebook(codebookId: Codebook['id']): Promise<Codebook> {
  return get(`/api/insights/catalogs/${codebookId}/codebook`).then(
    (response) => response.data
  );
}
/**
 * getEntries will return an array of entries - either all data up front if in "slow" loadingMode,
 * or paginated entries based on the parameters added including limit and page.
 * @param limit The number of entries that will be returned.
 * @param page Which page the user is currently navigating to; influences which segment of entries will be returned.
 */
export function getEntries({
  catalogId,
  limit,
  page,
  conversations,
  highlighters,
  participants,
  codes,
  annotation_ids,
  filters,
  filter_join,
}: EntriesRequest): Promise<EntriesEntities> {
  const queryParams = encodeQueryParams(
    {
      limit: NumberParam,
      page: NumberParam,
      author_ids: ArrayParam,
      conversation_ids: ArrayParam,
      primary_participant_ids: ArrayParam,
      code_ids: ArrayParam,
      annotation_ids: ArrayParam,
      filter_join: StringParam,
    },
    {
      limit: limit,
      page: page,
      author_ids: highlighters
        ? highlighters.map((c) => c.toString())
        : undefined,
      conversation_ids: conversations
        ? conversations.map((c) => c.toString())
        : undefined,
      primary_participant_ids: participants
        ? participants.map((c) => c.toString())
        : undefined,
      code_ids: codes ? codes.map((c) => c.toString()) : undefined,
      annotation_ids: annotation_ids
        ? annotation_ids.map((c) => c.toString())
        : undefined,
      filter_join: filter_join?.toString().toUpperCase(),
    }
  );
  const filtersQuery = filters ? `${getFiltersQuery(filters)}&` : '';

  return get(
    `/api/insights/catalogs/${catalogId}/entries?${filtersQuery}${stringify(
      queryParams
    )}`
  ).then((response) => response.data.entities);
}

export function getInsightsConversations(
  catalogId: Catalog['id']
): Promise<ConversationsEntities> {
  return get(`/api/insights/catalogs/${catalogId}/conversations`).then(
    (response) => response.data.entities
  );
}

export function markInternal(
  catalogId: Catalog['id'],
  entryId: Entry['id'],
  isInternal: Entry['is_internal']
): Promise<void> {
  return put(`/api/insights/catalogs/${catalogId}/entries`, undefined, {
    catalog_entry_id: entryId,
    is_internal: isInternal,
  });
}

export function unassignCoding(
  codeId: Code['id'],
  entryId: Entry['id']
): Promise<void> {
  return del('/api/insights/codings', undefined, {
    code_id: codeId,
    catalog_entry_id: entryId,
  });
}

export function unassignDemographic(
  demographicId: Demographic['id'],
  participantId: Participant['id']
): Promise<void> {
  return del('/api/insights/demographics/participants', undefined, {
    demographic_id: demographicId,
    participant_id: participantId,
  });
}

export function updateCatalog(catalog: Catalog): Promise<Catalog> {
  const { id, title, description, organization_id } = catalog;
  return put(`/api/insights/catalogs/${id}`, undefined, {
    organization_id: organization_id,
    title: title,
    description: description,
  }).then((response) => response.data);
}

export function updateCode(code: Code): Promise<Code> {
  return patch(`/api/insights/codes/${code.id}`, undefined, {
    color: code.color,
    description: code.description,
    name: code.name,
    parent_id: code.parent_id !== -1 ? code.parent_id : null,
  }).then((response) => response.data);
}

export function updateDemographic(
  demographic: Demographic
): Promise<Demographic> {
  return patch(`/api/insights/demographics/${demographic.id}`, undefined, {
    color: demographic.color,
    description: demographic.description,
    name: demographic.name,
    parent_id: demographic.parent_id !== -1 ? demographic.parent_id : null,
  }).then((response) => response.data);
}

export function setEntriesStatus(
  catalogId: Catalog['id'],
  catalog_entry_ids: Entry['id'][],
  status: Entry['status']
): Promise<void> {
  return post(`/api/insights/catalogs/${catalogId}/status`, undefined, {
    catalog_entry_ids,
    status,
  });
}

export function tagEntriesWithAI(
  catalogId: Catalog['id'],
  annotation_ids: Entry['annotation_id'][]
): Promise<AITaggingResponse> {
  return post(`/api/ai/coding/catalog/${catalogId}`, undefined, {
    annotation_ids,
  }).then((response) => response.data);
}

export function pollAITaggingRequests(
  catalogId: Catalog['id']
): Promise<AITaggingResponse> {
  return get(`/api/ai/coding/catalog/${catalogId}`).then(
    (response) => response.data
  );
}

export function cancelPendingAITaggingRequests(
  catalogId: Catalog['id']
): Promise<AITaggingResponse> {
  return del(`/api/ai/coding/catalog/${catalogId}`).then(
    (response) => response.data
  );
}
